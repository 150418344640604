<template>
  <div class="mianCont">
    <!-- 该区域 -->
    <div class="baiduHouseMapbtn" v-show="showSearchInfo">查询区域</div>
    <div class="clearLabel" @click="removeLabelEvent">{{!showHideLabelInfo ? '隐藏详情' : '显示详情'}}</div>
    <!-- @click="removeLabelEvent" -->
    <div @click="backSelf" class="locationSelf">
      <img style="width:25px;height:25px" src="../../assets/images/locationSelf.png" />
    </div>

    <rxNavBar
      v-if="androidFlg == false"
      :title="title"
      androidOrIOSFlag="true"
    ></rxNavBar>
    <rxNavBar v-else :title="title"></rxNavBar>
    <div class="head">
      <div class="headd">
        <div @click="selectCity" style="display: flex">
          <img class="cheeet" src="../../assets/images/3.png" />
          <div class="cheeec">{{ curCityName }}</div>
        </div>
        <div class="search" @click="showProject">
          筛选区域
          <!-- <div class="search-icon" @click="showProject"></div> -->
          <!-- <div @click="showProject" class="searchInputInfo">请筛选区域</div> -->
        </div>
      </div>
    </div>
    <!-- <div class="types">
      <span class="type" @click="showProject" id="downList">{{ projectName ? projectName : '全部' }}</span>
      <div :class="isShowProjectMenu?'small-icon-rotate':'small-icon'"></div>
    </div> -->
    <div class="slotCont"></div>
    <estateMap class="baiduHouseMap"> </estateMap>
    <van-popup
      v-model="isCheckInDateShow"
      position="bottom"
      :style="{ height: 'auto' }"
    >
      <div class="listMainInfo">
        <div
          v-for="(item, index) in listHourse"
          :key="index"
          @click="pageToDetail(item)"
          class="listMainInfoItem"
        >
          <div class="listMainInfoItemL">
            <img
              class="partsdt"
              :src="item.roomPhotoPath ? item.roomPhotoPath : house_image"
            />
          </div>
          <div class="listMainInfoItemR">
            <div>
              <div class="listMainInfoItemR1">
                {{ item.roomDetailedAddress }}
              </div>
              <div class="listMainInfoItemR2">
                {{ item.roomFuzzyAddress }}|{{ item.roomArea }}m²|{{
                  item.roomFloor
                }}层
              </div>
              <div class="listMainInfoItemR3">￥{{ item.roomRent }}/月</div>
              <div class="listMainInfoItemR4">
                <div class="listMainInfoItemR41">{{ item.subTypeStr }}</div>
                <div class="listMainInfoItemR42">
                  <span v-show="item.houseType == 0">整租</span>
                  <span v-show="item.houseType == 1">合租</span>
                  <span v-show="item.houseType == 2">集寓</span>
                  <span v-show="item.houseType == 3">独家</span>
                  <span v-if="item.roomCharacteristicList">
                    <span
                      class="statp"
                      v-if="item.roomCharacteristicList.length >= 1"
                      >{{
                        item.roomCharacteristicList[0].roomCharacteristicName
                      }}</span
                    >
                    <span
                      class="statp"
                      v-if="item.roomCharacteristicList.length >= 2"
                      >{{
                        item.roomCharacteristicList[1].roomCharacteristicName
                      }}</span
                    >
                    <span
                      class="statp"
                      v-if="item.roomCharacteristicList.length == 3"
                      >{{
                        item.roomCharacteristicList[2].roomCharacteristicName
                      }}</span
                    >
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-popup>
    <van-popup
      @close="closePupup"
      v-model="selectAreaChoose"
      position="bottom"
      :style="{ height: 'auto' }"
    >
      <div class="bChangeArea">
        <div class="bChangeAreaItem1">
          <div
            v-for="(item1, index1) in listCity1"
            :style="listCity1Index == index1 ? colorsare1 : uncolorsare1"
            :key="index1"
            class="bChangeAreaItem1L1"
            @click="searchAreaList(item1, index1)"
          >
            {{ item1.dictionaryTitle }}
          </div>
        </div>
        <div class="bChangeAreaItem2">
          <div
            v-for="(item2, index2) in listCity2"
            :style="listCity2Index == index2 ? colorsare1 : uncolorsare1"
            :key="index2"
            class="bChangeAreaItem1L"
            @click="searchAreaLiinfo(item2, index2)"
          >
            {{ item2.businessCircleName }}
          </div>
        </div>
      </div>
      <div class="bSearchArea">
        <div style="display: flex; align-items: center; white-space: nowrap">
          <div @click="cleanSpan()">
            <img class="clean-icon" src="../../assets/images/4.png" />
          </div>
          <div class="clean_button" @click="cleanSpan()">清空</div>
        </div>

        <div class="screen_button" @click="screenSpan()">筛选</div>
      </div>
    </van-popup>
    <van-loading
      class="vanLoading"
      type="spinner"
      color="#1989fa"
      v-if="loadingFlag"
    />
    <van-overlay class="vanOverlay" :show="loadingFlag" />
  </div>
</template>

<script>
import { NavBar, Popup, Notify, Loading, Overlay } from "vant";
import estateMap from "@/components/rongxun/rx-map/estateMap";
import eventBus from "../../libs/eventBus.js";
import {
  responseUtil,
  getStaffId,
  globaluserId,
  checkAndroid,
  checkIOS,
} from "../../libs/rongxunUtil";
import {
  queryBusinessCircleList,
  getQueryBaseData,
  queryCityList,
  searchingForMapRange,
  searchingForMap,
} from "../../getData/getData";
import rxNavBar from "../../components/rongxun/rx-navBar/navBar";

export default {
  name: "viewAddress",

  components: {
    [NavBar.name]: NavBar,
    [Loading.name]: Loading,
    [Overlay.name]: Overlay,
    [estateMap.name]: estateMap,
    [Popup.name]: Popup,
    rxNavBar,
  },
  data() {
    return {
      showHideLabelInfo: false,
      loadingFlag: false,
      title: "地图找房",
      androidFlg: "",
      listCity1Index: 0,
      listCity2Index: 0,
      colorsare1: { color: "#E47146", background: "#fff" },
      uncolorsare1: {},
      curCityName: "正在获取当前位置",
      searchValue: "",
      listHourse: [],
      showSearchInfo: false,

      longitude: "",
      latitude: "",

      projectName: "",
      // 弹层需要的参数
      houseMap: {
        title: "房源地图",
        buttonArray: [{ title: "关闭", click: (e) => this.close(e) }],
      },
      searchName: "",
      isCheckInDateShow: false,
      selectAreaChoose: false,
      pointsList: [],
      listCity1: [],
      listCity2: [],
      isShowProjectMenu: false,
      cityList: [],
      list: [],
      selectCityId: "",
      selectCityName: "",
      firstId: "",
      left: "",
      top: "",
      center: "",
      house_image: require("../../assets/images/house_image.png"),
      mapinfo: "",
      bid: "",
      qId: "",
      firstName: "",
      selectqCityId: "",
      selectbCityId: "",
      moveTimer: "",
      serachOnce: false,
      initLong: '',
      initLat: ''
    };
  },
  activated() {
    //根据key名获取传递回来的参数，data就是map
    this.searchValue = "";
    var that = this;
    eventBus.$on("houseList", function (data) {
      //  console.log("data,",data)

      that.loadingFlag = true;
      that.showSearchInfo = false;
      //  console.log(data,"data");
      that.curCityName = data.cityName;
      that.city_id = data.city_id;
      that.listCity2Index = 0;
      that.listCity1Index = 0;
      if (data.cityName == "沈阳") {
        that.longitude = "123.462617";
        that.latitude = "41.802704";
        that.initLong = "123.462617"
        that.initLat = "41.802704"
        var pt = new BMap.Point(that.longitude, that.latitude);
        that.mapinfo.centerAndZoom(pt, 13);
        that.initData();
      }
      if (data.city_id && data.cityName == "大连") {
        that.autoGetCity();
      }
    });

    // this.init()
  },
  watch: {},
  // beforeRouteEnter:(to, from, next)=> {
  // 已经弃用 存在第一次点击不生效问题
  //   next((vm) => {
  //     if(from.name == 'selectCity'){
  //       // alert(333)
  //       vm.$nextTick(() => {

  //         eventBus.$on(
  //           "houseList",
  //             function (data) {
  //               // alert(666)
  //               vm.loadingFlag = true
  //               vm.showSearchInfo = false
  //               console.log(data,"data");
  //                 vm.curCityName = data.cityName;
  //                 vm.city_id = data.city_id;
  //               vm.listCity2Index = 0
  //               vm.listCity1Index = 0
  //               if(data.cityName == "沈阳") {
  //                   // alert(111)
  //                   vm.longitude = "123.462617"
  //                   vm.latitude = "41.802704"
  //                   var pt = new BMap.Point(vm.longitude, vm.latitude);
  //                   vm.mapinfo.centerAndZoom(pt, 13);
  //                   vm.initData()
  //               }
  //               if(data.city_id && data.cityName=='大连') {
  //                   // alert(222)

  //                 vm.autoGetCity();
  //               }
  //             }
  //           );
  //       })
  //     }

  //   });

  //     // next()
  // },
  mounted: function () {
    this.serachOnce = true;
    this.loadingFlag = true;
    this.initowen();
    this.checkPhoneorMobel();
  },
  methods: {
    removeLabelEvent() {
      var that = this
      var zoomLevel = that.mapinfo.getZoom();
      console.log('当前缩放等级===',zoomLevel)
      that.showHideLabelInfo = !that.showHideLabelInfo
      if(!that.showHideLabelInfo) {
        that.mapinfo.clearOverlays();

        let length = that.pointsList.length ? that.pointsList.length : 0;
        
        // 遍历坐标点数组，为每个坐标点添加Marker
        that.pointsList.forEach(function (point) {
          var marker = new BMap.Marker(
            new BMap.Point(point.lon, point.lat)
          );
          var label = new BMap.Label(
            point.pedestalName + " " +
              point.tao +
              "套（" +
              point.jian +
              "间）" +
              point.minp +
              "元起",
            {
              offset: new BMap.Size(8, -24),
            }
          );
          label.setStyle({
            transform: "translateX(-50%)",
            color: "#fff",
            backgroundColor: "#DE3B0A",
            borderRadius: "5px",
            padding: "3px 5px",
          });

          // label.setVisible(false);
          marker.setLabel(label);
          // 阻止默认事件
          marker.addEventListener("click", function (point) {
            // console.log(point.target.point)
            clearTimeout(that.moveTimer);
            // alert(point.title);
            event.stopPropagation();
            const paramss = {
              centre: String(
                point.target.point.lng + "," + point.target.point.lat
              ),
            };
            searchingForMap(paramss).then((ress) => {
              responseUtil.dealResponse(that, ress, () => {
                that.listHourse = ress.data.data.data;
                // console.log(ress,"res====1111")
              });
            });
            that.isCheckInDateShow = true;
            that.showSearchInfo = false;
          });

          that.mapinfo.addOverlay(marker);
        });
      } else {
        that.mapinfo.clearOverlays();

        let length = that.pointsList.length ? that.pointsList.length : 0;
        
        // 遍历坐标点数组，为每个坐标点添加Marker
        that.pointsList.forEach(function (point) {
          var marker = new BMap.Marker(
            new BMap.Point(point.lon, point.lat)
          );
          // 阻止默认事件
          marker.addEventListener("click", function (point) {
            // console.log(point.target.point)
            clearTimeout(that.moveTimer);
            // alert(point.title);
            event.stopPropagation();
            const paramss = {
              centre: String(
                point.target.point.lng + "," + point.target.point.lat
              ),
            };
            searchingForMap(paramss).then((ress) => {
              responseUtil.dealResponse(that, ress, () => {
                that.listHourse = ress.data.data.data;
              });
            });
            that.isCheckInDateShow = true;
            that.showSearchInfo = false;
          });

          that.mapinfo.addOverlay(marker);
        });
      }
      
    },
    // 返回自身所在标记点
    backSelf() {
      this.longitude = this.initLong
      this.latitude = this.initLat
      this.mapinfo.clearOverlays();
      var pt = new BMap.Point(this.longitude, this.latitude);
      this.mapinfo.centerAndZoom(pt, 15);
    },
    checkPhoneorMobel() {
      if (checkAndroid()) {
        this.androidFlg = true;
      } else if (checkIOS()) {
        this.androidFlg = false;
      }
    },
    closePupup() {
      this.isShowProjectMenu = false;
    },
    leftReturn() {
      this.$router.go(-1);
    },
    searchSubmit() {
      var that = this;
      const params = {
        bId: "",
        qId: "",
        bName: that.searchValue,
        type: 1,
      };
      searchingForMapRange(params).then((res) => {
        responseUtil.dealResponse(that, res, () => {
          that.pointsList = res.data.data.dianMap;
          console.log(that.pointsList,"that.pointsList====")
          let length = that.pointsList.length ? that.pointsList.length : 0;
          if (length !== 0) {
            var long = that.pointsList[0].lon;
            var lat = that.pointsList[0].lat;

            var pt = new BMap.Point(long, lat);
            that.mapinfo.centerAndZoom(pt, 13);
            // 遍历坐标点数组，为每个坐标点添加Marker
            that.pointsList.forEach(function (point) {
              var marker = new BMap.Marker(
                new BMap.Point(point.lon, point.lat)
              );
              var label = new BMap.Label(
                point.pedestalName + " " +
                  point.tao +
                  "套（" +
                  point.jian +
                  "间）" +
                  point.minp +
                  "元起",
                {
                  offset: new BMap.Size(8, -24),
                }
              );
              label.setStyle({
                transform: "translateX(-50%)",
                color: "#fff",
                backgroundColor: "#DE3B0A",
                borderRadius: "5px",
                padding: "3px 5px",
              });

              marker.setLabel(label);
              
              // 阻止默认事件
              marker.addEventListener("click", function (point) {
                // console.log(point.target.point)
                clearTimeout(that.moveTimer);
                // alert(point.title);
                event.stopPropagation();
                const paramss = {
                  centre: String(
                    point.target.point.lng + "," + point.target.point.lat
                  ),
                };
                searchingForMap(paramss).then((ress) => {
                  responseUtil.dealResponse(that, ress, () => {
                    that.listHourse = ress.data.data.data;
                    // console.log(ress,"res====1111")
                  });
                });
                that.isCheckInDateShow = true;
                that.showSearchInfo = false;
              });

              that.mapinfo.addOverlay(marker);
            });
          }

          // console.log(res.data," res.data====")
          // that.pointsList = res.data.data.dianMap
        });
      });
      this.selectAreaChoose = false;
    },

    cleanSpan() {
      var that = this;
      that.selectbCityId = "";
      that.selectqCityId = that.selectqCityId;
      that.selectCityName = that.firstName;
      that.listCity1Index = 0;
      that.listCity2Index = 0;
      that.bId = "";
      that.qId = "";
      const params = {
        city_id: that.city_id,
        cityProper_id: that.selectqCityId,
      };
      queryBusinessCircleList(params).then((res) => {
        responseUtil.dealResponse(that, res, () => {
          that.listCity2 = res.data.data.businessCircleList;
          that.listCity2.unshift({ businessCircleName: "不限", id: "" });
        });
      });
    },
    screenSpan() {
      this.showHideLabelInfo = false
      var that = this;
      const params = {
        bId: that.selectbCityId,
        qId: that.selectqCityId,
        // bName: that.selectCityName,
        type: 1,
      };
      searchingForMapRange(params).then((res) => {
        responseUtil.dealResponse(that, res, () => {
          that.pointsList = res.data.data.dianMap;
          let length = that.pointsList.length ? that.pointsList.length : 0;
          if (length == 0) {
            Notify({ type: "warning", message: "未在该地区找到可租房源" });
            return;
          }
          if (length !== 0) {
            that.mapinfo.clearOverlays();
            var long = that.pointsList[0].lon;
            var lat = that.pointsList[0].lat;

            var pt = new BMap.Point(long, lat);
            that.mapinfo.centerAndZoom(pt, 18);
            // 遍历坐标点数组，为每个坐标点添加Marker
            that.pointsList.forEach(function (point) {
              var marker = new BMap.Marker(
                new BMap.Point(point.lon, point.lat)
              );
              var label = new BMap.Label(
                point.pedestalName + " " +
                  point.tao +
                  "套（" +
                  point.jian +
                  "间）" +
                  point.minp +
                  "元起",
                {
                  offset: new BMap.Size(8, -24),
                }
              );
              label.setStyle({
                transform: "translateX(-50%)",
                color: "#fff",
                backgroundColor: "#DE3B0A",
                borderRadius: "5px",
                padding: "3px 5px",
              });

              marker.setLabel(label);
              // 阻止默认事件
              marker.addEventListener("click", function (point) {
                // console.log(point.target.point)
                clearTimeout(that.moveTimer);
                // alert(point.title);
                event.stopPropagation();
                const paramss = {
                  centre: String(
                    point.target.point.lng + "," + point.target.point.lat
                  ),
                };
                searchingForMap(paramss).then((ress) => {
                  responseUtil.dealResponse(that, ress, () => {
                    that.listHourse = ress.data.data.data;
                    // console.log(ress,"res====1111")
                  });
                });
                that.isCheckInDateShow = true;
                that.showSearchInfo = false;
              });

              that.mapinfo.addOverlay(marker);
            });
          }
          // console.log(res.data," res.data====")
          // that.pointsList = res.data.data.dianMap
        });
      });
      this.selectAreaChoose = false;
    },
    showProject() {
      // TODO:
      this.selectAreaChoose = true;
      this.isShowProjectMenu = true;
      clearTimeout(this.moveTimer);
      this.showSearchInfo = false;
    },
    pageToDetail(item) {
      this.$router.push({
        name: "houseDetails",
        query: {
          room_id: item.room_id,
          roomStatus: item.subType,
          roomStatusStr: item.subTypeStr,
        },
      });
    },
    selectCity() {
      this.$router.push({
        name: "selectCity",
        query: { curCityName: this.curCityName },
      });
    },
    searchAreaList(item, index) {
      this.selectqCityId = item.id;
      this.qId = item.id;
      this.selectCityName = item.dictionaryTitle;
      this.listCity1Index = index;
      this.listCity2Index = 0;
      var that = this;
      const params = {
        city_id: that.city_id,
        cityProper_id: item.id,
      };
      queryBusinessCircleList(params).then((res) => {
        responseUtil.dealResponse(that, res, () => {
          that.listCity2 = res.data.data.businessCircleList;
          that.listCity2.unshift({ businessCircleName: "不限", id: "" });
          // console.log(res,"res=======")
        });
      });
    },
    searchAreaLiinfo(item, index) {
      this.bid = item.id;
      this.selectbCityId = item.id;
      this.selectCityName = item.businessCircleName;
      this.listCity2Index = index;
    },
    autoGetCity() {
      let that = this;
      var geolocation = new BMap.Geolocation();

      geolocation.getCurrentPosition(function (r) {
        if (this.getStatus() == BMAP_STATUS_SUCCESS) {
          var city = r.address.city; //返回当前城市
          that.curCityName = city;
          that.longitude = r.longitude;
          that.latitude = r.latitude;

          that.initLong = r.longitude
          that.initLat = r.latitude
          // console.log(r,"自身定位信息====")
          let city_id = "";
          for (let i = 0; i < that.cityList.length; i++) {
            if (that.cityList[i].cityName + "市" == city) {
              city_id = that.cityList[i].city_id;
            }
          }

          // console.log(city_id,"city_id=====")
          if (city_id) {
            that.city_id = city_id;
            that.initData();
          }
        }
      });
    },
    autoGetowenCity() {
      // navigator.geolocation.getCurrentPosition(showPosition, showError);
      // function showPosition(position) {
      //     var point = new BMap.Point(position.coords.longitude, position.coords.latitude);
      //     map.centerAndZoom(point, 15);
      //     map.addOverlay(new BMap.Marker(point));
      // }
      // // 处理错误信息
      // function showError(error) {
      //     switch (error.code) {
      //         case error.PERMISSION_DENIED:
      //             alert("用户拒绝对获取地理位置的请求。");
      //             break;
      //         case error.POSITION_UNAVAILABLE:
      //             alert("位置信息是不可用的。");
      //             break;
      //         case error.TIMEOUT:
      //             alert("请求用户地理位置超时。");
      //             break;
      //         case error.UNKNOWN_ERROR:
      //             alert("未知错误。");
      //             break;
      //     }
      // }

      let that = this;
      var geolocation = new BMap.Geolocation();
      var gc = new BMap.Geocoder(); //创建地理编码器

      // 开启SDK辅助定位
      geolocation.enableSDKLocation();

      geolocation.getCurrentPosition(function (r) {
        if (this.getStatus() == BMAP_STATUS_SUCCESS) {
          var city = r.address.city; //返回当前城市
          that.curCityName = city;
          that.longitude = r.longitude;
          that.latitude = r.latitude;

          that.initLong = r.longitude
          that.initLat = r.latitude
          // console.log(r,"自身定位信息====")
          let city_id = "";
          for (let i = 0; i < that.cityList.length; i++) {
            if (that.cityList[i].cityName + "市" == city) {
              city_id = that.cityList[i].city_id;
            }
          }

          // console.log(city_id,"city_id=====")
          if (city_id) {
            that.city_id = city_id;
            that.initData();
          } else {
            that.initData();

          }
        }
      });
    },
    init() {
      let that = this;
      queryCityList({}).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          that.list = response.data.data.cityList;
          that.cityList = that.list;
          that.autoGetCity();
        });
      });
    },
    initowen() {
      let that = this;
      if (that.mapinfo) {
        that.mapinfo.clearOverlays();
      }
      queryCityList({}).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          that.list = response.data.data.cityList;
          that.cityList = that.list;
          that.autoGetowenCity();
        });
      });
    },
    initData() {
      var that = this;
      const params = {
        city_id: that.city_id,
        cityProperid: "",
      };
      queryBusinessCircleList(params).then((res) => {
        responseUtil.dealResponse(that, res, () => {
          that.listCity1 = res.data.data.cityProperList;
          that.selectqCityId = that.listCity1[0].id;
          that.firstId = that.listCity1[0].id;
          that.firstName = that.listCity1[0].dictionaryTitle;
          // console.log(that.selectCityId,"that.selectCityId==")
          that.listCity2 = res.data.data.businessCircleList;
          that.listCity2.unshift({ businessCircleName: "不限", id: "" });
          // console.log(res,that.listCity1,"res=======")
          that.createCircle();
        });
      });
    },

    //初始化地图
    createCircle: function () {
      var that = this;
      that.mapinfo = new BMap.Map("container");
      var top_right_navigation = new BMap.NavigationControl({
        anchor: BMAP_ANCHOR_TOP_RIGHT,
        type: BMAP_NAVIGATION_CONTROL_SMALL,
      });
      //创建中心点
      var pt = new BMap.Point(this.longitude, this.latitude);
      // var pt = new BMap.Point(121.599704, 38.914892);
      that.mapinfo.centerAndZoom(pt, 15);
      that.mapinfo.addControl(top_right_navigation);
      that.mapinfo.setMinZoom(8);
      that.mapinfo.setMaxZoom(20); 
      // 仅初始化触发一次
      if (that.serachOnce) {
        var lnglat = that.mapinfo.getCenter();

        // 获取当前中心点的经纬度
        var bs = that.mapinfo.getBounds();
        var bssw = bs.getSouthWest();
        var bsne = bs.getNorthEast();
        // 左侧和上侧经纬度
        that.left = String(bssw.lng + "," + lnglat.lat);
        that.top = String(lnglat.lng + "," + bsne.lat);
        that.center = String(lnglat.lng + "," + lnglat.lat);
        // that.center = '121.599704' + ',' + '38.914892'
        const params = {
          centre: that.center,
          left: that.left,
          top: that.top,
          type: 2,
        };
        // console.log(params,"params============")
        // 处理坐标点
        searchingForMapRange(params).then((res) => {
          responseUtil.dealResponse(that, res, () => {
            that.pointsList = res.data.data.dianMap;
            let length = that.pointsList.length ? that.pointsList.length : 0;
            if (length == 0) {
              Notify({ type: "warning", message: "未在该地区找到可租房源" });
            }
            // 遍历坐标点数组，为每个坐标点添加Marker
            that.pointsList.forEach(function (point) {
              var marker = new BMap.Marker(
                new BMap.Point(point.lon, point.lat)
              );
              var label = new BMap.Label(
                point.pedestalName + " " +
                  point.tao +
                  "套（" +
                  point.jian +
                  "间）" +
                  point.minp +
                  "元起",
                {
                  offset: new BMap.Size(8, -24),
                }
              );
              label.setStyle({
                transform: "translateX(-50%)",
                color: "#fff",
                backgroundColor: "#DE3B0A",
                borderRadius: "5px",
                padding: "3px 5px",
              });

              marker.setLabel(label);
              
              // 阻止默认事件
              marker.addEventListener("click", function (point) {
                // console.log(point.target.point)
                clearTimeout(that.moveTimer);
                // alert(point.title);
                event.stopPropagation();
                const paramss = {
                  centre: String(
                    point.target.point.lng + "," + point.target.point.lat
                  ),
                };
                searchingForMap(paramss).then((ress) => {
                  responseUtil.dealResponse(that, ress, () => {
                    that.listHourse = ress.data.data.data;

                    // console.log(ress,"res====1111")
                  });
                });
                that.showSearchInfo = false;
                that.serachOnce = false;
              });

              that.mapinfo.addOverlay(marker);
            });
          });
        });
      }
      

      var btnDom = document.querySelector(".baiduHouseMapbtn");
      if (btnDom) {
        btnDom.addEventListener("click", function () {
          // alert(111)
          // that.showHideLabelInfo = false
          that.mapinfo.clearOverlays();

          const params = {
            centre: that.center,
            left: that.left,
            top: that.top,
            type: 2,
          };
          // console.log(params,"params============")
          // 处理坐标点
          searchingForMapRange(params).then((res) => {
            responseUtil.dealResponse(that, res, () => {
              that.pointsList = res.data.data.dianMap;
              let length = that.pointsList.length ? that.pointsList.length : 0;
              if (length == 0) {
                Notify({ type: "warning", message: "未在该地区找到可租房源" });
              }
              var zoomLevel = that.mapinfo.getZoom();
              console.log('当前缩放等级===',zoomLevel)

              // 遍历坐标点数组，为每个坐标点添加Marker
              that.pointsList.forEach(function (point) {
                var marker = new BMap.Marker(
                  new BMap.Point(point.lon, point.lat)
                );
                if(zoomLevel >= 18) {
                  that.showHideLabelInfo = false
                  var label = new BMap.Label(
                    point.pedestalName + " " +
                      point.tao +
                      "套（" +
                      point.jian +
                      "间）" +
                      point.minp +
                      "元起",
                    {
                      offset: new BMap.Size(8, -24),
                    }
                  );
                  label.setStyle({
                    transform: "translateX(-50%)",
                    color: "#fff",
                    backgroundColor: "#DE3B0A",
                    borderRadius: "5px",
                    padding: "3px 5px",
                  });
  
                  // label.setVisible(false);
                  marker.setLabel(label);

                } else {
                  that.showHideLabelInfo = true
                }
                // 阻止默认事件
                marker.addEventListener("click", function (point) {
                  // console.log(point.target.point)
                  clearTimeout(that.moveTimer);
                  // alert(point.title);
                  event.stopPropagation();
                  const paramss = {
                    centre: String(
                      point.target.point.lng + "," + point.target.point.lat
                    ),
                  };
                  searchingForMap(paramss).then((ress) => {
                    responseUtil.dealResponse(that, ress, () => {
                      that.listHourse = ress.data.data.data;
                      // console.log(ress,"res====1111")
                    });
                  });
                  that.isCheckInDateShow = true;
                  that.showSearchInfo = false;
                });

                that.mapinfo.addOverlay(marker);
              });
            });
          });
          that.showSearchInfo = false;
        });
      }

      // 处理地图缩放事件
      that.mapinfo.addEventListener("zoomend", function (event) {
        // var zoomLevel = event;
        var lnglat = that.mapinfo.getCenter();

        // 获取当前中心点的经纬度
        var bs = that.mapinfo.getBounds();
        var bssw = bs.getSouthWest();
        var bsne = bs.getNorthEast();
        // 左侧和上侧经纬度
        that.left = String(bssw.lng + "," + lnglat.lat);
        that.top = String(lnglat.lng + "," + bsne.lat);
        that.center = String(lnglat.lng + "," + lnglat.lat);
        that.showSearchInfo = true;
      });

      // 处理地图移动事件
      // var moveTimer; // 移动计时器
      var moveCount = 0; // 移动次数
      var moveTotalLength = 0; // 移动总长度
      var moveCenter = new BMap.Point(0, 0); // 移动区域中心点坐标
      var moveBounds = null; // 移动区域边界

      that.mapinfo.addEventListener("moveend", function (e) {
        clearTimeout(that.moveTimer); // 清除定时器
        that.moveTimer = setTimeout(function () {
          // var center = map.getCenter();
          if (moveCount > 0) {
            var center = that.mapinfo.getCenter(); // 获取当前地图中心点坐标
            moveTotalLength += Math.sqrt(
              Math.pow(center.lng - moveCenter.lng, 2) +
                Math.pow(center.lat - moveCenter.lat, 2)
            ); // 计算移动总长度
            moveCenter = center; // 更新移动区域中心点坐标
            moveCount++; // 更新移动次数
          } else {
            var center = that.mapinfo.getCenter(); // 获取当前地图中心点坐标
            moveTotalLength += Math.sqrt(
              Math.pow(center.lng - moveCenter.lng, 2) +
                Math.pow(center.lat - moveCenter.lat, 2)
            ); // 计算移动总长度
            moveCenter = center; // 更新移动区域中心点坐标
            moveCount++; // 更新移动次数
          }
          moveBounds = that.mapinfo.getBounds(); // 获取当前地图边界范围

          // 获取当前中心点的经纬度
          var bs = that.mapinfo.getBounds();
          var bssw = bs.getSouthWest();
          var bsne = bs.getNorthEast();

          // 左侧和上侧经纬度
          that.left = String(bssw.lng + "," + moveCenter.lat);
          that.top = String(moveCenter.lng + "," + bsne.lat);
          that.center = String(moveCenter.lng + "," + moveCenter.lat);

          // console.log("当前移动区域中心点：", moveCenter,moveBounds); // 输出当前移动区域中心点坐标
          if (!that.selectAreaChoose) {
            that.showSearchInfo = true;
          }
        }, 500); // 延迟200毫秒执行计算操作，避免频繁触发事件导致性能问题
      });

      // 处理地图点击事件
      // that.mapinfo.addEventListener("click", function (e) {
      //   // 获取点击坐标点
      //   var clickPoint = e.point;

      //   that.mapinfo.centerAndZoom(clickPoint, 14);

      //   // 获取当前中心点的经纬度
      //   var bs = that.mapinfo.getBounds();
      //   var bssw = bs.getSouthWest();
      //   var bsne = bs.getNorthEast();
      //   // 左侧和上侧经纬度
      //   that.left = String(bssw.lng + "," + clickPoint.lat);
      //   that.top = String(clickPoint.lng + "," + bsne.lat);
      //   that.center = String(clickPoint.lng + "," + clickPoint.lat);

      //   // console.log("点击坐标点：", clickPoint);
      //   that.showSearchInfo = true;
      // });

      // 添加圆形区域
      // var marker = new BMap.Marker(pt);
      // var circle = new BMap.Circle(pt, 300, {
      //   strokeColor: "Red",
      //   strokeWeight: 1,
      //   strokeOpacity: 1,
      //   Color: "Red",
      //   fillColor: "#f03",
      //   fillOpacity: 0.2,
      // }); // 创建圆形区域

      // map.addOverlay(circle);

      // 遍历坐标点数组，为每个坐标点添加Marker
      // that.pointsList.forEach(function (point) {
      //   var marker = new BMap.Marker(new BMap.Point(point.lon, point.lat));
      //   var label = new BMap.Label(point.pedestalName + "共有:" +point.tao + "套 最低价格" + point.minp + "元", {
      //     offset: new BMap.Size(8, -24),
      //   });
      //   label.setStyle({
      //     transform: "translateX(-50%)",
      //     color: "#fff",
      //     backgroundColor: "#DE3B0A",
      //     borderRadius: "5px",
      //     padding: "3px 5px",
      //   });

      //   marker.setLabel(label);
      //   // 阻止默认事件
      //   marker.addEventListener("click", function () {
      //     // alert(point.title);
      //     event.stopPropagation();
      //     that.isCheckInDateShow = true
      //   });

      //   map.addOverlay(marker);
      // });
      // map.addOverlay(new BMap.Point(point.lon, point.lat))
      that.loadingFlag = false;
    },
  },
};
</script>

<style scoped>
.mianCont {
  height: 100vh;
  background: #fff;
}
.searchInput {
  /* background: #f60; */
  position: fixed;
  /* top: 50px; */
  margin-left: 50px;
  /* z-index: 9; */
  width: 100%;
}
.slotCont {
  height: 20px;
}
.baiduHouseMap {
  width: 100% !important;
  height: calc(100vh - 125px) !important;
  border-radius: 0px !important;
  /* background: skyblue; */
}
.baiduHouseMapbtn {
  /* width: 60px; */
  opacity: 0.7;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  color: #fff;
  padding: 5px;
  height: 20px;
  background: #f60;
  z-index: 99999 !important;
  position: fixed;
  top: 280px;
  right: 10px;
  /* transform: translate(-50%, -50%); */
  font-size: 12px;
}
.clearLabel {
  /* width: 60px; */
  opacity: 0.7;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  color: #fff;
  padding: 5px;
  height: 20px;
  background: #f60;
  z-index: 99 !important;
  position: fixed;
  top: 320px;
  right: 10px;
  /* transform: translate(-50%, -50%); */
  font-size: 12px;
}
.locationSelf {
  /* opacity: 0.8; */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  color: #fff;
  width: 25px;
  height: 25px;
  background: #fff;
  padding: 5px;
  z-index: 99 !important;
  position: fixed;
  top: 230px;
  right: 10px;
  /* transform: translate(-50%, -50%); */
  font-size: 12px;
}
.search {
  margin: 15px 20px 0 20px;
  height: 35px;
  border-radius: 30px;
  background-color: #f4f4f4;
  display: flex;
  align-items: center;
}
.search-icon {
  width: 25px;
  height: 25px;
  margin-left: 10px;
  background-image: url("../../assets/images/search-icon.png");
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
}
input {
  font-size: 14px;
  margin-left: 15px;
  margin-top: 3px;
  background-color: #f4f4f4;
  border: 0;
  width: 250px;
}
.listMainInfo {
  padding: 10px 0 20px 0;
  width: 100%;
  height: 40vh;
  overflow: auto;
  background: #f8f8f8;
}
.listMainInfoItem {
  padding: 5px;
  margin: 10px;
  height: 100px;
  background: #fff;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
}
.listMainInfoItemL {
  width: 35%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.listMainInfoItemR {
  width: 60%;
  height: 100%;
  /* background: sandybrown; */
  display: flex;
  /* flex-direction: column; */
  align-items: center;
}
.listMainInfoItemR1 {
  /* background: #fff; */
  font-size: 14px;
  margin-top: 2px;
}
.listMainInfoItemR2 {
  /* background: seagreen; */
  font-size: 12px;
  margin-top: 2px;
  color: #868181;
}
.listMainInfoItemR3 {
  /* background: seagreen; */
  font-size: 14px;
  margin-top: 2px;
  color: #fe7f60;
}
.listMainInfoItemR4 {
  display: flex;
  /* background: #fe5e3a; */
  font-size: 11px;
  margin-top: 2px;
}
.listMainInfoItemR41 {
  margin-right: 5px;
  background: #deebfb;
  padding: 2px 4px;
  color: #96bdeb;
  border-radius: 4px;
}
.listMainInfoItemR42 {
  margin-right: 5px;
  background: #f5d8cf;
  padding: 2px 4px;
  color: #ff6c6e;
  border-radius: 4px;
}

.bChangeArea {
  height: 400px;
  /* background: #f60; */
  display: flex;
}
.bChangeAreaItem1 {
  width: 35%;
  height: 100%;
  overflow: auto;
  background: #f2f2f2;
}
.bChangeAreaItem2 {
  width: 65%;
  height: 100%;
  overflow: auto;
  background: #fff;
  color: #a8a8a8;
}
.bChangeAreaItem3 {
  width: 40%;
  height: 100%;
  background: pink;
  overflow: auto;
}
.bChangeAreaItem1L {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  font-size: 16px;
  height: 28px;
  padding: 10px;
}
.bChangeAreaItem1L1 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  height: 28px;
  padding: 10px;
}
.bSearchArea {
  height: 50px;
  /* background: skyblue; */
  margin: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
/*垃圾桶*/
.clean-icon {
  height: 20px;
  /*padding: 0 5px 0 23px;*/
  margin-right: 10px;
  margin-left: 10px;
  width: 16px;
}

/*清空*/
.clean_button {
  /*position: absolute;*/
  /*top: 83px;*/
  font-size: 14px;
  color: rgba(255, 93, 59, 1);
}

/*筛选按钮*/
.screen_button {
  /*position: absolute;*/
  /*left: 150px;*/
  /*padding: 11px 31px;*/
  width: 80px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  border-radius: 6px;
  color: rgb(255, 255, 255);
  border: none;
  background: linear-gradient(rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
  background: -webkit-linear-gradient(
    left,
    rgba(255, 194, 116, 1),
    rgba(255, 93, 59, 1)
  );
  /* Safari 5.1 - 6.0 */
  background: -o-linear-gradient(
    right,
    rgba(255, 194, 116, 1),
    rgba(255, 93, 59, 1)
  );
  /* Opera 11.1 - 12.0 */
  background: -moz-linear-gradient(
    right,
    rgba(255, 194, 116, 1),
    rgba(255, 93, 59, 1)
  );
  /* Firefox 3.6 - 15 */
  background: linear-gradient(
    to right,
    rgba(255, 194, 116, 1),
    rgba(255, 93, 59, 1)
  );
}

.types {
  width: 100%;
  height: 47px;
  z-index: 99;
  /* background: #f60; */
  display: flex;
  position: fixed;
  top: 100px;
  align-items: center;
}

.type {
  text-align: left;
  color: #fe5e3a;
  margin-left: 15px;
  color: rgba(255, 93, 59, 1);
  font-size: 14px;
  font-family: PingFangSC-Regular;
}

.small-icon {
  width: 8px;
  height: 8px;
  margin-left: 5px;
  background-image: url("../../assets/images/small-icon.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

.small-icon-rotate {
  width: 8px;
  height: 8px;
  margin-bottom: 5px;
  margin-left: 5px;
  background-image: url("../../assets/images/small-icon.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  transform: rotate(180deg);
}
.head {
  width: 100%;
  position: fixed;
  z-index: 8;
  top: 40px;
  overflow: hidden;
  /* background: #ffffff; */
}

.headd {
  padding: 10px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.search {
  /*margin: 60px 20px 0 0;*/
  width: 80%;
  height: 35px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #fff;
  background-image: linear-gradient(to right, #ffc274, #ff5d3b);
  display: flex;
  align-items: center;
}

.cheeec {
  margin-right: 10px;
  font-size: 15px;
  font-weight: 500;
  margin-top: 15px;
}

.cheeet {
  margin-top: 15px;
  margin-right: 10px;
  width: 27px;
  height: 20px;
}

.partsdt {
  width: 130px;
  height: 95px;
  fit: contain;
  border-radius: 10px;
}
.statp {
  width: 125px;
  background-color: #deebfb;
  color: #86b3e8;
  font-size: 10px;
  text-align: center;
  border-radius: 2px;
  padding: 2px 4px;
  margin-right: 8px;
}
.vanLoading {
  position: fixed;
  z-index: 3333;
  top: 50%;
  left: 47%;
}
.vanOverlay {
  z-index: 2222;
}
.searchInputInfo {
  height: 20px;
  margin-left: 12px;
  width: 5rem;
  /* background: red; */
  font-size: 12px;
  display: flex;
  align-items: center;
  color: #d7d7d7;
}
</style>
